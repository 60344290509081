import { Navigation } from "./components/navigation";

import { Home } from "./pages/home";
import { Careers } from "./pages/careers";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import {ExternalRedirect} from "./components/externalRedirect"

import "./App.css";
import { Footer } from "./components/footer";

const App = () => {
  return (
    <Router>
      <div>
        <Navigation />
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/h1b-consultants" element={<ExternalRedirect to="https://forms.quanterian.com/Quanterian/form/JobApplication/formperma/Dkvw_5HpXFVFuk3PfJKYQgObdSYn5Rl6-LCjqg8Z47Y"/>} />
        <Route path="/consultants1" element={<ExternalRedirect to="https://forms.quanterian.com/Quanterian/form/ClientDetails/formperma/NqpIsYOaN3aSOd8hbyhKwRfIkOKgsqiQZf9GPT3Xo24"/>} />
        <Route path="/gc-consultants" element={<ExternalRedirect to="https://forms.quanterian.com/Quanterian/form/H1I140Visa/formperma/XP9sHXvwoMh4jgCjYbwQBmwYu72BPdV0baS0-gKL6Wg"/>} />
        <Route path="/interns" element={<ExternalRedirect to="https://forms.quanterian.com/Quanterian/form/OPTInternship/formperma/V2PSFBZmS1LEQLf3mMI_eaPTS3lXjGyljaWQcnl60D0"/>} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
